.headerBlock {
    width: 100%;
    display:flex;
    justify-content:center;
}

.headerTitle{
    padding-left: 85px;
    font-size:25px;
    flex:7;
    text-align:center;
    margin-top:50px;
    font-weight: 300;
    font-family: 'Courier New', sans-serif;
}

.headerRight{
    padding-top: 20px;
    flex:3;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color:brown;
    font-size:40px;
}
.headerLeft{
    padding-top: 20px;
    flex:3;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color:brown;
    font-size:40px;
}
