.Home {
}

.homeTop {
    display: flex;
}

.homeTopTitle {
    margin-top:20px;
    margin-bottom:20px;
    text-align: center;
    color:rgb(118, 82, 151);
    font-size:39px;
}

.homeTopBody {
    flex:8;
    font-family:'sans-serif';
    font-size:x-large;
    line-height:1.6;
    word-spacing:10px;
}

.homeFig {
    flex: 2;
    padding-top: 20px;
}


.profileImg {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width:320px;
    align-items: center;
}