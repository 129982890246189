.topBar{
    position: fixed;
    top: 0px;
    width: 100%;
    height:50px;
    display: flex;
    align-items:center;
    font-family:"Josefin Sans", sans-serif;
    z-index:999;
    background-color: rgb(237, 250, 246);
}
.tpProfile {
    height:40px;
    width:40px;
    border-radius: 30px;
}

.topBarCenter{
    flex:3 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBarLeft, .topBarRight{
    flex:3 1;
    display: flex;
}
.tblItems {
    margin: 0;
    padding: 0;
    list-style:none;    
    display:flex;
}

.tblItem {
    margin-right: 20px;
    font-size: 25px;
    font-weight:300;
    cursor:pointer;
}
.tblItem:hover {
    background-color:rgb(231, 189, 231);
}

.topBarRight {
    justify-content: right;
}

.login {
    margin-right:20px;
}



.headerBlock {
    width: 100%;
    display:flex;
    justify-content:center;
}

.headerTitle{
    padding-left: 85px;
    font-size:25px;
    flex:7 1;
    text-align:center;
    margin-top:50px;
    font-weight: 300;
    font-family: 'Courier New', sans-serif;
}

.headerRight{
    padding-top: 20px;
    flex:3 1;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color:brown;
    font-size:40px;
}
.headerLeft{
    padding-top: 20px;
    flex:3 1;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color:brown;
    font-size:40px;
}

.Blogs {
}

.BlogTitle {
    text-align: center;
    font-family: sans-serif;
    font-size:25pt;
    margin-top:30px;
    margin-bottom: 2opx;
    color:rgb(188, 143, 229);
    background-color: rgb(244, 248, 252);
}

.BlogEntry {
    display:flex;
    flex-direction: column;
}

.BlogItem {
    text-decoration: none;
    color:rgb(147, 185, 201);
    font-size:25pt;
    display: flex    
}

.BlogItemName {
    flex: 8 1;
    text-decoration: none;
    font-size: 27pt;
}

.BlogItemDate {
    flex: 2 1;
    text-align: center;
}

.BlogItem:hover {
    background-color:rgb(230, 223, 235);
}

.BlogTitle {
    flex-direction: row;
    display: flex;
}

.blogT {
    flex:9.5 1
}

.adminMode {
    flex: 0.5 1
}

.adminMode:hover {
    background-color:rgb(248, 230, 218);
}

.deleteBlog:hover {
    background-color: rgb(175, 116, 116);
}

.editBlog:hover {
    background-color: rgb(175, 116, 116);
}
.Publication {

}

.pubTitle {
    font-size:50px;
    font-family: "Cambria";
}

.pubs {

}

.pubEntry {
    font-size:22px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.pubHeader {
    display: flex;
}

.cvloc {
    color: red;
}
.Home {
}

.homeTop {
    display: flex;
}

.homeTopTitle {
    margin-top:20px;
    margin-bottom:20px;
    text-align: center;
    color:rgb(118, 82, 151);
    font-size:39px;
}

.homeTopBody {
    flex:8 1;
    font-family:'sans-serif';
    font-size:x-large;
    line-height:1.6;
    word-spacing:10px;
}

.homeFig {
    flex: 2 1;
    padding-top: 20px;
}


.profileImg {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width:320px;
    align-items: center;
}
.Blog {
    font-family:"Josefin Sans", sans-serif;
    font-size: 18pt;
    color: rgb(0, 0, 7);
}
.addBlog {
    margin-top: 40px;
    font-size: xx-large;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.blgName {
    margin-top: 20px;
    border:none;
    width:1200px;
    height:50px;
    background-color: rgb(228, 234, 240);
    font-size: x-large;
}

.addBlog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blgContent {
    margin-top : 20px;
    height: 600px;
    width: 1200px;
    background-color: rgb(228, 234, 240);
    font-size: large;
}


.submit {
    margin-top : 20px;
    height: 50px;
    width:150px;
    border: none;
    font-size: xx-large;
    border-radius: 0.2em;
}

.select_format {
    height: 50px;
    width: 150px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: none;
    background-color: rgb(188, 231, 217);
    text-align: center;
    font-size: 25px;
}
.mainBody {
    display: flex;
}

.LeftBlock {
    flex: 1.5 1;
}

.RightBlock {
    flex: 1.5 1;
}

.bodyBlock {
    flex: 7 1;
}



.red {
    margin:20px;
    padding:30px;
}

.red:hover {
    background-color:blanchedalmond;
}
