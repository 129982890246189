.Blogs {
}

.BlogTitle {
    text-align: center;
    font-family: sans-serif;
    font-size:25pt;
    margin-top:30px;
    margin-bottom: 2opx;
    color:rgb(188, 143, 229);
    background-color: rgb(244, 248, 252);
}

.BlogEntry {
    display:flex;
    flex-direction: column;
}

.BlogItem {
    text-decoration: none;
    color:rgb(147, 185, 201);
    font-size:25pt;
    display: flex    
}

.BlogItemName {
    flex: 8;
    text-decoration: none;
    font-size: 27pt;
}

.BlogItemDate {
    flex: 2;
    text-align: center;
}

.BlogItem:hover {
    background-color:rgb(230, 223, 235);
}

.BlogTitle {
    flex-direction: row;
    display: flex;
}

.blogT {
    flex:9.5
}

.adminMode {
    flex: 0.5
}

.adminMode:hover {
    background-color:rgb(248, 230, 218);
}

.deleteBlog:hover {
    background-color: rgb(175, 116, 116);
}

.editBlog:hover {
    background-color: rgb(175, 116, 116);
}