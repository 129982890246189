.addBlog {
    margin-top: 40px;
    font-size: xx-large;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.blgName {
    margin-top: 20px;
    border:none;
    width:1200px;
    height:50px;
    background-color: rgb(228, 234, 240);
    font-size: x-large;
}

.addBlog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blgContent {
    margin-top : 20px;
    height: 600px;
    width: 1200px;
    background-color: rgb(228, 234, 240);
    font-size: large;
}


.submit {
    margin-top : 20px;
    height: 50px;
    width:150px;
    border: none;
    font-size: xx-large;
    border-radius: 0.2em;
}

.select_format {
    height: 50px;
    width: 150px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: none;
    background-color: rgb(188, 231, 217);
    text-align: center;
    font-size: 25px;
}