.mainBody {
    display: flex;
}

.LeftBlock {
    flex: 1.5;
}

.RightBlock {
    flex: 1.5;
}

.bodyBlock {
    flex: 7;
}



.red {
    margin:20px;
    padding:30px;
}

.red:hover {
    background-color:blanchedalmond;
}