.topBar{
    position: fixed;
    top: 0px;
    width: 100%;
    height:50px;
    display: flex;
    align-items:center;
    font-family:"Josefin Sans", sans-serif;
    z-index:999;
    background-color: rgb(237, 250, 246);
}
.tpProfile {
    height:40px;
    width:40px;
    border-radius: 30px;
}

.topBarCenter{
    flex:3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBarLeft, .topBarRight{
    flex:3;
    display: flex;
}
.tblItems {
    margin: 0;
    padding: 0;
    list-style:none;    
    display:flex;
}

.tblItem {
    margin-right: 20px;
    font-size: 25px;
    font-weight:300;
    cursor:pointer;
}
.tblItem:hover {
    background-color:rgb(231, 189, 231);
}

.topBarRight {
    justify-content: right;
}

.login {
    margin-right:20px;
}


