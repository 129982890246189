.Publication {

}

.pubTitle {
    font-size:50px;
    font-family: "Cambria";
}

.pubs {

}

.pubEntry {
    font-size:22px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.pubHeader {
    display: flex;
}

.cvloc {
    color: red;
}